.main-menu-text {
    margin-left: 5px;
}

.ul-nopadding {
    margin: 0px !important;
    padding-inline-start: 20px;
}

.helpFieldMsg {
    margin-left: 20px !important;
    color: $palette-link;
}

.floating-menu {
    display: inline;
    position: absolute;
    z-index: 10000;
    min-width: 240px;
}

.floating-menu-right {
    position: absolute;
    right: 15px;
    z-index: 10000;
    min-width: 240px;
}

.textAreaFooterMsg {
    margin-left: 0px !important;
    color: $palette-link;
}

.blueTextMessages {
    font-size: 12pt;
    color: $palette-link;
}

.button-to-link {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-size: 11pt;
    font-style: Italic;
    color: $palette-link;
    text-decoration: underline;
    cursor: pointer;
}

.button-to-link2 {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: #069;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
        color: #069;
    }
}

.card-bg-AdminCard-main {
    margin-bottom: 0px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.card-bg-FullAdmin {
    margin-bottom: 0px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
}

.card-bg-AdminCard-details {
    margin-bottom: 0px;
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
}

.card-bg-TableQuilleditor {
    margin-bottom: 0px;
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
}

.dropdowns-Background {
    margin-bottom: 0px;
    padding-top: 0px;
    background-color: $deferral-background-3;
}

.quillMd {
    min-width: -webkit-fill-available;
    //width: 750px !important;
    //flex: none !important;
}

.quillCard {
    min-width: -webkit-fill-available;
    //width: 850px !important;
    //flex: none !important;
}

:root {
    /* Deferredcomp.morganstanleyatwork.com Color Palette */
    --setups: #2196f3;
    --setups-light: #e3f2fd;
    --setups-dark: $deferral-setup-1;
    --participants: #f44336;
    --participants-light: #ffebee;
    --participants-dark: #b71c1c;
    --funds: #4caf50;
    --funds-light: #e8f5e9;
    --funds-dark: #1b5e20;
    --plans: #ff9800;
    --plans-light: #fff3e0;
    --plans-dark: #e65100;
}

/* Setup Areas: Blue */

.bg-setup {
    font-size: 1rem;
    font-weight: bold;
    border-color: $deferral-setup-4 !important;
    color: $deferral-text !important;
    background-color: $deferral-setup-4 !important;
    box-shadow: none !important;

    &.active {
        color: $deferral-background !important;
        background-color: $deferral-setup-4 !important;
    }
}

.bg-setup-light {
    background-color: transparent;
}

.card-bg-setup-main {
    margin-bottom: 0px;
    padding: 0px !important;
    border-top: solid;
    border-top-color: $deferral-setup-1a;
    border-top-width: 1px;
}

.card-bg-setup {
    background-color: $deferral-setup-4a;
    border-bottom: none;
}

.card-bg-setup-messages {
    background-color: $deferral-background-2;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0px;
}

.card-bg-setup-options {
    background-color: $deferral-background-2;
    padding-top: 5px;
    padding-bottom: 10px;
}

.card-bg-setup-options-2 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.bg-setup-dark {
    background-color: var(--setup-dark) !important;
}

.card.bg-setup {
    background-color: $deferral-setup-7;
    border-color: $deferral-background-2;
}

.btn-setup {
    border-color: $deferral-setup-4a !important;
    color: $deferral-text !important;
    background-color: $deferral-setup-4a !important;
    &.active {
        color: $topmenu-active-text !important;
        background-color: $deferral-setup-4a !important;
    }
}

.btn-main-setup {
    &.active {
        background-color: $deferral-setup-1b;
    }
}

.text-setup {
    color: $deferral-text;
    &.active {
        color: $topmenu-active-text;
    }
}

.text-setup-light {
    color: var(--setup-light) !important;
}

.text-setup-dark {
    color: $deferral-text;
    font-size: 1rem !important;
}

/* Funds Areas: Green */

.bg-funds {
    font-size: 1rem;
    font-weight: bold;
    border-color: $deferral-fund-4 !important;
    color: $deferral-text !important;
    background-color: $deferral-fund-4 !important;
    box-shadow: none !important;

    &.active {
        color: $deferral-background !important;
        background-color: $deferral-fund-4 !important;
    }
}

.bg-funds-light {
    background-color: transparent;
}

.card-bg-funds-main {
    margin-bottom: 0px;
    border-top: solid;
    border-top-color: $deferral-fund-1a;
    border-top-width: 1px;
    padding: 0px !important;
}

.card-bg-funds {
    background-color: $deferral-fund-4a;
    border-bottom: none;
}

.card-bg-funds-messages {
    background-color: $deferral-background-2;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0px;
}

.card-bg-funds-options {
    background-color: $deferral-background-2;
    padding-top: 5px;
    padding-bottom: 10px;
}

.card-bg-funds-options-2 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.bg-funds-dark {
    background-color: var(--funds-dark) !important;
}

.card.bg-funds {
    background-color: $deferral-fund-7;
    border-color: $deferral-background-2;
}

.btn-fund {
    border-color: $deferral-fund-4a !important;
    color: $deferral-text !important;
    background-color: $deferral-fund-4a !important;

    &.active {
        color: $topmenu-active-text !important;
        background-color: $deferral-fund-4a !important;
    }
}

.btn-main-fund {
    &.active {
        background-color: $deferral-fund-1b;
    }
}

.text-funds {
    color: $deferral-text;
    &.active {
        color: $topmenu-active-text;
    }
}

.text-funds-light {
    color: var(--funds-light) !important;
}

.text-funds-dark {
    color: $deferral-text;
    font-size: 1rem !important;
}

/* Plans Areas: Red */

.bg-plans {
    font-size: 1rem;
    font-weight: bold;
    border-color: $deferral-plan-4 !important;
    color: $deferral-text !important;
    background-color: $deferral-plan-4 !important;
    box-shadow: none !important;

    &.active {
        color: $deferral-background !important;
        background-color: $deferral-plan-4 !important;
    }
}

.bg-plans-light {
    background-color: transparent;
}

.card-bg-plans-main {
    margin-bottom: 0px;
    border-top: solid;
    border-top-color: $deferral-plan-1a;
    border-top-width: 1px;
    padding: 0px !important;
}

.card-bg-plans {
    background-color: $deferral-plan-4a;
    border-bottom: none;
}

.card-bg-plans-messages {
    background-color: $deferral-background-2;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0px;
}

.card-bg-plans-options {
    background-color: $deferral-background-2;
    padding-top: 5px;
    padding-bottom: 10px;
}

.card-bg-plans-options-2 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.bg-plans-dark {
    background-color: var(--plans-dark) !important;
}

.card.bg-plans {
    background-color: $deferral-plan-7;
    border-color: $deferral-background-2;
}

.btn-plan {
    border-color: $deferral-plan-4a !important;
    color: $deferral-text !important;
    background-color: $deferral-plan-4a !important;

    &.active {
        color: $topmenu-active-text !important;
        background-color: $deferral-plan-4a !important;
    }
}

.btn-main-plan {
    &.active {
        background-color: $deferral-plan-1b;
    }
}

.text-plans {
    color: $deferral-text;
    &.active {
        color: $topmenu-active-text;
    }
}

.text-plans-light {
    color: var(--plans-light) !important;
}

.text-plans-dark {
    color: $deferral-text;
    font-size: 1rem !important;
}

/* Participants Areas:  */

.bg-participants {
    font-size: 1rem;
    font-weight: bold;
    border-color: $deferral-participant-4 !important;
    color: $deferral-text !important;
    background-color: $deferral-participant-4 !important;
    box-shadow: none !important;

    &.active {
        color: $deferral-background !important;
        background-color: $deferral-participant-4 !important;
    }
}

.bg-participants-light {
    background-color: transparent;
}

.card-bg-participants-main {
    margin-bottom: 0px;
    border-top: solid;
    border-top-color: $deferral-participant-1a;
    border-top-width: 1px;
    padding: 0px !important;
}

.card-bg-participants {
    background-color: $deferral-participant-4a;
    border-bottom: none;
}

.card-bg-participants-messages {
    background-color: $deferral-background-2;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0px;
}

.card-bg-participants-options {
    background-color: $deferral-background-2;
    padding-top: 5px;
    padding-bottom: 10px;
}

.card-bg-participants-options-2 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.bg-participants-dark {
    background-color: var(--participants-dark) !important;
}

.card.bg-participants {
    background-color: $deferral-participant-7;
    border-color: $deferral-background-2;
}

.btn-participant {
    border-color: $deferral-participant-4a !important;
    color: $deferral-text !important;
    background-color: $deferral-participant-4a !important;

    &.active {
        color: $topmenu-active-text !important;
        background-color: $deferral-participant-4a !important;
    }
}

.btn-main-participant {
    &.active {
        background-color: $deferral-participant-1b;
    }
}

.text-participants {
    color: $deferral-text;
    &.active {
        color: $topmenu-active-text;
    }
}

.text-participants-light {
    color: var(--participants-light) !important;
}

.text-participants-dark {
    color: $deferral-text;
    font-size: 1rem !important;
}

/* Processes Areas: Green */

.bg-processes {
    font-size: 1rem;
    font-weight: bold;
    border-color: $deferral-process-5 !important;
    color: $deferral-text !important;
    background-color: $deferral-process-5 !important;
    box-shadow: none !important;

    &.active {
        color: $deferral-background !important;
        background-color: $deferral-process-5 !important;
    }
}

.bg-processes-light {
    background-color: transparent;
}

.card-bg-processes-main {
    margin-bottom: 0px;
    border-top: solid;
    border-top-color: $deferral-process-2a;
    border-top-width: 1px;
    padding: 0px !important;
}

.card-bg-processes {
    background-color: $deferral-process-5a;
    border-bottom: none;
}

.card-bg-processes-messages {
    background-color: $deferral-background-2;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0px;
}

.card-bg-processes-options {
    background-color: $deferral-background-2;
    padding-top: 5px;
    padding-bottom: 10px;
}

.card-bg-processes-options-2 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.bg-processes-dark {
    background-color: var(--processes-dark) !important;
}

.card.bg-processes {
    background-color: $deferral-process-7;
    border-color: $deferral-background-2;
}

.btn-process {
    border-color: $deferral-process-5a !important;
    color: $deferral-text !important;
    background-color: $deferral-process-5a !important;

    &.active {
        color: $topmenu-active-text !important;
        background-color: $deferral-process-5a !important;
    }
}

.btn-main-process {
    &.active {
        background-color: $deferral-process-1b;
    }
}

.text-processes {
    color: $deferral-text;
    &.active {
        color: $topmenu-active-text;
    }
}

.text-processes-light {
    color: var(--processes-light) !important;
}

.text-processes-dark {
    color: $deferral-text;
    font-size: 1rem !important;
}

/* Utilities Areas: Green */

.bg-utilities {
    font-size: 1rem;
    font-weight: bold;
    border-color: $deferral-utility-6 !important;
    color: $deferral-text !important;
    background-color: $deferral-utility-6 !important;
    box-shadow: none !important;

    &.active {
        color: $deferral-background !important;
        background-color: $deferral-utility-6 !important;
    }
}

.bg-utilities-light {
    background-color: transparent;
}

.card-bg-utilities-main {
    margin-bottom: 0px;
    border-top: solid;
    border-top-color: $deferral-utility-3a;
    border-top-width: 1px;
    padding: 0px !important;
}

.card-bg-utilities {
    background-color: $deferral-utility-6a;
    border-bottom: none;
}

.card-bg-utilities-messages {
    background-color: $deferral-background-2;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0px;
}

.card-bg-utilities-options {
    background-color: $deferral-background-2;
    padding-top: 5px;
    padding-bottom: 10px;
}

.card-bg-utilities-options-2 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.bg-utilities-dark {
    background-color: var(--utilities-dark) !important;
}

.card.bg-utilities {
    background-color: $deferral-utility-7;
    border-color: $deferral-background-2;
}

.btn-utility {
    border-color: $deferral-utility-6a !important;
    color: $deferral-text !important;
    background-color: $deferral-utility-6a !important;

    &.active {
        color: $topmenu-active-text !important;
        background-color: $deferral-utility-6a !important;
    }
}

.btn-main-utility {
    &.active {
        background-color: $deferral-utility-1b;
    }
}

.text-utilities {
    color: $deferral-text;
    &.active {
        color: $topmenu-active-text;
    }
}

.text-utilities-light {
    color: var(--utilities-light) !important;
}

.text-utilities-dark {
    color: $deferral-text;
    font-size: 1rem !important;
}

/* Assets Areas: Green */

.bg-assets {
    font-size: 1rem;
    font-weight: bold;
    border-color: $deferral-asset-4 !important;
    color: $deferral-text !important;
    background-color: $deferral-asset-4 !important;
    box-shadow: none !important;

    &.active {
        color: $deferral-background !important;
        background-color: $deferral-asset-4 !important;
    }
}

.bg-assets-light {
    background-color: transparent;
}

.card-bg-assets-main {
    margin-bottom: 0px;
    border-top: solid;
    border-top-color: $deferral-asset-1a;
    border-top-width: 1px;
    padding: 0px !important;
}

.card-bg-assets {
    background-color: $deferral-asset-4a;
    border-bottom: none;
}

.card-bg-assets-messages {
    background-color: $deferral-background-2;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0px;
}

.card-bg-assets-options {
    background-color: $deferral-background-2;
    padding-top: 5px;
    padding-bottom: 10px;
}

.card-bg-assets-options-2 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.bg-assets-dark {
    background-color: var(--assets-dark) !important;
}

.card.bg-assets {
    background-color: $deferral-asset-7;
    border-color: $deferral-background-2;
}

.btn-asset {
    border-color: $deferral-asset-4a !important;
    color: $deferral-text !important;
    background-color: $deferral-asset-4a !important;

    &.active {
        color: $topmenu-active-text !important;
        background-color: $deferral-asset-4a !important;
    }
}

.btn-main-asset {
    &.active {
        background-color: $deferral-asset-1b;
    }
}

.text-assets {
    color: $deferral-text;
    &.active {
        color: $topmenu-active-text;
    }
}

.text-assets-light {
    color: var(--assets-light) !important;
}

.text-assets-dark {
    color: $deferral-text;
    font-size: 1rem !important;
}

.docSectionTitle {
    //font-size: 14pt;
    font-size: 1.09375rem;
    //font-family: Verdana;
    font-weight: 500; //bold;
    color: $palette-group-title;
}

.iframeEnrollment {
    height: 380px;
}
