$color_1: #242424;
$color_2: #fff;

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/material-icons/MaterialIcons-Regular.eot');
    src:
        local('Material Icons'),
        local('MaterialIcons-Regular'),
        url('./fonts/material-icons/MaterialIcons-Regular.woff2') format('woff2'),
        url('./fonts/material-icons/MaterialIcons-Regular.woff') format('woff'),
        url('./fonts/material-icons/MaterialIcons-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'ET Iconography';
    src: url('./fonts/et-iconography/et-iconography.woff2');
    src:
        url('./fonts/et-iconography/et-iconography.woff2') format('embedded-opentype'),
        url('./fonts/et-iconography/et-iconography.ttf') format('truetype'),
        url('./fonts/et-iconography/et-iconography.woff') format('woff'),
        url('./fonts/et-iconography/et-iconography.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

.material-icons {
    font-family: 'Material Icons';
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: inherit;
}

.et-icon {
    font-family: 'ET Iconography';
    speak: none;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    letter-spacing: 0;
    pointer-events: none;
    font-feature-settings: 'liga' 1;
    font-variant-ligatures: discretionary-ligatures;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // color: $color_1;
    text-align: center;
}

.et-icon.et-inverse {
    color: $color_2;
}

.et-icon.et-icon-xxs {
    font-size: 20px !important;
}

.material-icons.et-icon-xxs {
    font-size: 20px !important;
}

.et-icon.et-icon-xs {
    font-size: 24px !important;
}

.material-icons.et-icon-xs {
    font-size: 24px !important;
}

.et-icon.et-icon-sm {
    font-size: 36px !important;
}

.material-icons.et-icon-sm {
    font-size: 36px !important;
}

.et-icon.et-icon-md {
    font-size: 48px !important;
}

.material-icons.et-icon-md {
    font-size: 48px !important;
}

.et-icon.et-icon-lg {
    font-size: 72px !important;
}

.material-icons.et-icon-lg {
    font-size: 72px !important;
}

.et-icon.et-icon-xl {
    font-size: 96px !important;
}

.material-icons.et-icon-xl {
    font-size: 96px !important;
}

$icon-sizes: (
    xxs: 20px,
    xs: 24px,
    sm: 36px,
    md: 48px,
    lg: 72px,
    xl: 96px
);

$et-icons: (
    'add-funds': '\e900',
    'alarm-clock': '\e901',
    'alert-help': '\e902',
    'alert': '\e903',
    'anniversary': '\e904',
    'anniversary-multiple': '\e905',
    'bank': '\e906',
    'calculator': '\e930',
    'calendar': '\e907',
    'cheque': '\e908',
    'clock': '\e909',
    'compass': '\e931',
    'computer': '\e90a',
    'date': '\e928',
    'downturn': '\e90b',
    'facts-research': '\e90c',
    'faqs-fact-sheet': '\e90d',
    'gear-settings': '\e90e',
    'gift': '\e90f',
    'goal': '\e910',
    'graduation-gift': '\e911',
    'group-family': '\e912',
    'growth': '\e913',
    'help-customer-svc': '\e914',
    'help-more-info': '\e915',
    'home-lending': '\e916',
    'idea': '\e917',
    'ira-selector': '\e931',
    'money-auto-investing': '\e918',
    'money-automatic-investing': '\e918',
    'money': '\e919',
    'money-investing': '\e91a',
    'online-tutorial-laptop': '\e91b',
    'research-awareness': '\e91c',
    'retirement': '\e91d',
    'savings': '\e91e',
    'security': '\e91f',
    'smartphone': '\e920',
    'stopwatch': '\e921',
    'tool-box-welcome-kit': '\e922',
    'top-five': '\e923',
    'umbrella': '\e924',
    'user': '\e925',
    'users': '\e926',
    'video': '\e927',
    'chat': '\e932',
    'transaction': '\e933',
    'school': '\e934',
    'search': '\e935',
    'dropped-pin': '\e936',
    'video-laptop': '\e937',
    'smartphone-landscape': '\e938',
    'health-care': '\e939',
    'balanced': '\e940',
    'basket': '\e941',
    'money-envelope': '\e942',
    'checkmark': '\e943',
    'cheque-deposit': '\e944',
    'chequebook': '\e945',
    'stop-pay': '\e946',
    'message-center': '\e947',
    'screen-share': '\e948',
    'credit-card-front': '\e949',
    'credit-card-back': '\e950',
    'envelope': '\e951'
);

.material-icons,
.text-nowrap {
    white-space: nowrap;
}

.et-icon-block {
    display: block !important;
}

@each $size-name, $font-size in $icon-sizes {
    .et-icon,
    .material-icons {
        &.et-icon-#{$size-name} {
            font-size: $font-size !important;
        }
    }
}

@each $icon-name, $icon-character in $et-icons {
    .et-#{$icon-name}:before {
        content: '#{$icon-character}';
    }
}
