body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.font-xs {
    font-size: 0.75rem !important;
}

.font-sm {
    font-size: 0.85rem !important;
}

.font-lg {
    font-size: 1rem !important;
}

.font-xl {
    font-size: 1.25rem !important;
}

.font-2xl {
    font-size: 1.5rem !important;
}

.font-3xl {
    font-size: 1.75rem !important;
}

.font-4xl {
    font-size: 2rem !important;
}

.font-5xl {
    font-size: 2.5rem !important;
}
