.row.row-equal {
    padding-right: calc($grid-gutter-width / 4);
    padding-left: calc($grid-gutter-width / 4);
    margin-right: calc($grid-gutter-width / -2);
    margin-left: calc($grid-gutter-width / -2);

    [class*='col-'] {
        padding-right: calc($grid-gutter-width / 4);
        padding-left: calc($grid-gutter-width / 4);
    }
}

.main-admin .container-fluid {
    padding: 10px !important;
    min-height: 350px;
}

.main .container-fluid {
    padding: 0 30px;
}
