// Here you can add other styles
.navbar {
    .form-inline {
        i {
            color: $navbar-color;
        }
        .form-control {
            margin-top: -3px;
            color: $navbar-color;
            border: 0;

            &::placeholder {
                color: $navbar-color;
            }
        }
        .form-control::-webkit-input-placeholder {
            color: $navbar-color;
        }
        .form-control:-moz-placeholder {
            color: $navbar-color;
        }
        .form-control::-moz-placeholder {
            color: $navbar-color;
        }
        .form-control:-ms-input-placeholder {
            color: $navbar-color;
        }
    }
    .nav-item {
        .nav-link.nav-pill {
            width: 38px;
            height: 38px;
            padding: 0;
            line-height: 38px;
            border: 1px solid $navbar-color;
            border-radius: 50em;

            &:hover {
                border-color: $navbar-hover-color;
            }

            .badge-pill {
                margin-top: -18px;
                margin-left: -5px;
                border: 2px solid #fff !important;
                line-height: 14px;
            }

            &.avatar {
                width: 38px;
                height: 38px;

                img {
                    width: 36px;
                    height: 36px;
                    margin: 0;
                    border: 0;
                }

                .badge {
                    margin-top: -22px;
                    margin-left: 5px;
                }

                &.dropdown-toggle:after {
                    display: none;
                }
            }
        }
    }
}

.sidebar .sidebar-nav .nav {
    .nav-title {
        span {
            position: relative;
            display: inline-block;

            &:before,
            &:after {
                position: absolute;
                top: 50%;
                width: 25px;
                height: 1px;
                margin-top: -1px;
                content: '';
                border-bottom: 1px solid $sidebar-nav-title-color;
            }

            &:before {
                left: -30px;
            }

            &:after {
                right: -30px;
            }
        }
    }
}
