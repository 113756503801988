@mixin switch-size($width, $height, $font-size, $handle-margin) {
    width: $width;
    height: $height;

    .switch-label {
        font-size: $font-size;
    }

    .switch-handle {
        width: $height - $handle-margin * 2;
        height: $height - $handle-margin * 2;
    }

    .switch-input:checked ~ .switch-handle {
        left: $width - $height + $handle-margin;
    }
}

@mixin switch($type, $width, $height, $font-size, $handle-margin) {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: $width;
    height: $height;
    background-color: transparent;
    cursor: pointer;

    .switch-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    .switch-label {
        position: relative;
        display: block;
        height: inherit;
        @if $type == icon {
            font-family: FontAwesome;
        }
        font-size: $font-size;
        font-weight: 600;
        text-transform: uppercase;
        @if $type == ddd {
            background-color: $gray-100;
        } @else {
            background-color: red;
        }
        border: 1px solid $border-color;
        border-radius: 2px;
        //  transition: opacity background 0.15s ease-out;
    }
    @if $type == text or $type == icon {
        .switch-label::before,
        .switch-label::after {
            position: absolute;
            top: 50%;
            width: 50%;
            margin-top: -0.5em;
            line-height: 1;
            text-align: center;
            transition: inherit;
        }
        .switch-label::before {
            right: 1px;
            color: #fff;
            content: attr(data-off);
        }
        .switch-label::after {
            left: 1px;
            color: #fff;
            content: attr(data-on);
            opacity: 0;
        }
    }
    .switch-input:checked ~ .switch-label {
        //background: $gray-lightest;
    }
    .switch-input:checked ~ .switch-label::before {
        opacity: 1;
    }
    .switch-input:checked ~ .switch-label::after {
        opacity: 1;
    }

    .switch-handle {
        position: absolute;
        top: $handle-margin;
        left: $handle-margin;
        width: $height - $handle-margin * 2;
        height: $height - $handle-margin * 2;
        background: #fff;
        border: 1px solid $border-color;
        border-radius: 1px;
        transition: left 0.15s ease-out;
        @if $type == ddd {
            border: 0;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        }
    }

    .switch-input:checked ~ .switch-handle {
        left: $width - $height + $handle-margin;
    }

    @if $type == ddd {
        @extend .switch-pill;
    }

    //size variations
    @if $type == default {
        &.switch-lg {
            @include switch-size($switch-lg-width, $switch-lg-height, $switch-lg-font-size, $handle-margin);
        }
        &.switch-sm {
            @include switch-size($switch-sm-width, $switch-sm-height, $switch-sm-font-size, $handle-margin);
        }
        &.switch-xs {
            @include switch-size($switch-xs-width, $switch-xs-height, $switch-xs-font-size, $handle-margin);
        }
    } @else if $type == text {
        &.switch-lg {
            @include switch-size($switch-text-lg-width, $switch-text-lg-height, $switch-text-lg-font-size, $handle-margin);
        }
        &.switch-sm {
            @include switch-size($switch-text-sm-width, $switch-text-sm-height, $switch-text-sm-font-size, $handle-margin);
        }
        &.switch-xs {
            @include switch-size($switch-text-xs-width, $switch-text-xs-height, $switch-text-xs-font-size, $handle-margin);
        }
    } @else if $type == icon {
        &.switch-lg {
            @include switch-size($switch-icon-lg-width, $switch-icon-lg-height, $switch-icon-lg-font-size, $handle-margin);
        }
        &.switch-sm {
            @include switch-size($switch-icon-sm-width, $switch-icon-sm-height, $switch-icon-sm-font-size, $handle-margin);
        }
        &.switch-xs {
            @include switch-size($switch-icon-xs-width, $switch-icon-xs-height, $switch-icon-xs-font-size, $handle-margin);
        }
    } @else if $type == ddd {
        &.switch-lg {
            @include switch-size($switch-lg-width, $switch-lg-height, $switch-lg-font-size, 0);
        }
        &.switch-sm {
            @include switch-size($switch-sm-width, $switch-sm-height, $switch-sm-font-size, 0);
        }
        &.switch-xs {
            @include switch-size($switch-xs-width, $switch-xs-height, $switch-xs-font-size, 0);
        }
    }
}

@mixin switch-variant($color) {
    > .switch-input:checked ~ .switch-label {
        background: $color !important;
        border-color: darken($color, 10%);
    }

    > .switch-input:checked ~ .switch-handle {
        border-color: darken($color, 10%);
    }
}

@mixin switch-outline-variant($color) {
    > .switch-input:checked ~ .switch-label {
        background: #fff !important;
        border-color: $color;

        &::after {
            color: $color;
        }
    }

    > .switch-input:checked ~ .switch-handle {
        border-color: $color;
    }
}

@mixin switch-outline-alt-variant($color) {
    > .switch-input:checked ~ .switch-label {
        background: #fff !important;
        border-color: $color;

        &::after {
            color: $color;
        }
    }

    > .switch-input:checked ~ .switch-handle {
        background: $color !important;
        border-color: $color;
    }
}

$switch-lg-width: 48px;
$switch-lg-height: 28px;
$switch-lg-font-size: 12px;

$switch-width: 40px;
$switch-height: 24px;
$switch-font-size: 10px;

$handle-margin: 2px;

$switch-sm-width: 32px;
$switch-sm-height: 20px;
$switch-sm-font-size: 8px;

$switch-xs-width: 24px;
$switch-xs-height: 16px;
$switch-xs-font-size: 7px;

$switch-text-lg-width: 56px;
$switch-text-lg-height: 28px;
$switch-text-lg-font-size: 12px;

$switch-text-width: 48px;
$switch-text-height: 24px;
$switch-text-font-size: 10px;

$switch-text-sm-width: 40px;
$switch-text-sm-height: 20px;
$switch-text-sm-font-size: 8px;

$switch-text-xs-width: 32px;
$switch-text-xs-height: 16px;
$switch-text-xs-font-size: 7px;

$switch-icon-lg-width: 56px;
$switch-icon-lg-height: 28px;
$switch-icon-lg-font-size: 12px;

$switch-icon-width: 48px;
$switch-icon-height: 24px;
$switch-icon-font-size: 10px;

$switch-icon-sm-width: 40px;
$switch-icon-sm-height: 20px;
$switch-icon-sm-font-size: 8px;

$switch-icon-xs-width: 32px;
$switch-icon-xs-height: 16px;
$switch-icon-xs-font-size: 7px;

.switch.switch-default {
    @include switch('default', $switch-width, $switch-height, $switch-font-size, $handle-margin);
}

.switch.switch-text {
    @include switch('text', $switch-text-width, $switch-text-height, $switch-text-font-size, $handle-margin);
}

.switch.switch-icon {
    @include switch('icon', $switch-icon-width, $switch-icon-height, $switch-icon-font-size, $handle-margin);
}

.switch.switch-3d {
    @include switch('ddd', $switch-width, $switch-height, $switch-font-size, 0);
}

//pills style
.switch-pill {
    .switch-label,
    .switch-handle {
        border-radius: 50em !important;
    }

    .switch-label::before {
        right: 2px !important;
    }
    .switch-label::after {
        left: 2px !important;
    }
}

@each $color, $value in $theme-colors {
    //normal style
    .switch-#{$color} {
        @include switch-variant($value);
    }
    //outline style
    .switch-#{$color}-outline {
        @include switch-outline-variant($value);
    }
    //outline alternative style
    .switch-#{$color}-outline-alt {
        @include switch-outline-alt-variant($value);
    }
}
