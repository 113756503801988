.table-outline {
    border: 1px solid $table-border-color;

    td {
        vertical-align: middle;
    }
}

.table-align-middle {
    td {
        vertical-align: middle;
    }
}

.table-clear {
    td {
        border: 0;
    }
}
