@font-face {
    font-family: 'graphik';
    src: url('fonts/graphik/GraphikETRADE-Regular-Web.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'graphik';
    src: url('fonts/graphik/GraphikETRADE-RegularItalic-Web.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'graphik';
    src: url('fonts/graphik/GraphikETRADE-Medium-Web.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'graphik';
    src: url('fonts/graphik/GraphikETRADE-MediumItalic-Web.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'graphik';
    src: url('fonts/graphik/GraphikETRADE-Semibold-Web.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'graphik';
    src: url('fonts/graphik/GraphikETRADE-SemiboldItalic-Web.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'GraphikETRADECompact';
    src: url('fonts/graphik/GraphikETRADECompact-Medium-Web.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'GraphikETRADECompact';
    src: url('fonts/graphik/GraphikETRADECompact-Regular-Web.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'GraphikETRADECompact';
    src: url('fonts/graphik/GraphikETRADECompact-Semibold-Web.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
