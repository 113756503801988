// scss-lint:disable all
.animated {
    animation-duration: 1s;
    // animation-fill-mode: both;
}

.animated.infinite {
    animation-iteration-count: infinite;
}

.animated.hinge {
    animation-duration: 2s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}
