$ms-logo-lg: URL('../../public/img/msaw-master-logo-suite-rgb-lockup-k_Deferred-Compensation.png') !default;
$ms-logo-sm: URL('../../public/img/msaw-master-logo-suite-rgb-lockup-horizstacked_k_DeferredCompensation.png') !default;
$ms-env-sit-lg: URL('../../public/img/GUI-Env-Label-SIT.png') !default;
$ms-env-sit-sm: URL('../../public/img/GUI-Env-Label-SIT.png') !default;
$ms-env-uat-lg: URL('../../public/img/GUI-Env-Label-UAT.png') !default;
$ms-env-uat-sm: URL('../../public/img/GUI-Env-Label-UAT.png') !default;
$ms-env-qa-lg: URL('../../public/img/GUI-Env-Label-QA.png') !default;
$ms-env-qa-sm: URL('../../public/img/GUI-Env-Label-QA.png') !default;
// Override core variables
h2 {
    color: $palette-group-title !important;
}

h3 {
    color: $palette-group-title !important;
}

h4 {
    color: $palette-group-title !important;
}

h5 {
    color: $palette-group-title !important;
}

.sso-panel {
    //border: 1px solid #ccc;
    //min-width: 320px;
    //border-radius: 10px;
    //box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.iframe-container {
    /* To position the loading */
    position: relative;
}

.iframe-loading {
    /* Absolute position */
    left: 0;
    position: relative;
    top: 0;

    /* Take full size */
    height: 100%;
    width: 100%;

    /* Center */
    text-align: center;
    display: block;
    font-weight: bold;
    cursor: pointer;
}

.modal-content {
    border-radius: $deferral-modal-radius;
}
.modal-header {
    background-color: $deferral-header-bck;
    border-top-left-radius: $deferral-modal-radius;
    border-top-right-radius: $deferral-modal-radius;
}
.modal-title {
    color: $deferral-header-text !important;
}

.dividerLine {
    border-top: '1px solid gray' !important;
    width: '90%' !important;
    margin-top: '12px' !important;
    margin-left: '5%' !important;
}

.selectHg {
    height: 24px;
}

.leftMenu-subItem {
    margin-left: 30px !important;
}

.app-migration-banner {
    .migration-header {
        position: fixed;
        height: max-content;
        width: 100%;
        padding: 0;
        margin: 0;
        z-index: 1020;
        .app-header {
            position: static !important;
            border-bottom: none;
            height: none;
        }
        border-bottom: $palette-text 1px solid;
    }
    .app-body {
        margin-top: 127px !important;
    }
    .alert {
        margin-bottom: 0px;
        .ms-text {
            color: $palette-text;
            font-size: 14px;
        }
    }
}

.col95 {
    max-width: 95%;
    flex: 0 0 95%;
    padding-right: 0px;
}
.col5 {
    max-width: 5%;
    flex: 0 0 5%;
    padding-left: 5px;
}

.text-icon-main-menu-tile {
    color: $deferral-text;
    &.active {
        color: $topmenu-active-text;
    }
}

.app-header.navbar .navbar-toggler {
    outline: none;
}

.font-body {
    font-family: $font-family !important;
    //font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    //Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.app-body {
    background-color: $deferral-background-4;
}

.progress {
    font-size: 0.6rem;
    height: 15px;
}

.flexnowrap {
    flex-flow: nowrap;
}

.minwidth50 {
    min-width: 50px;
}

.minwidth100 {
    min-width: 100px;
}

.minwidth150 {
    min-width: 150px;
}

.minwidth200 {
    min-width: 200px;
}

.minwidth300 {
    min-width: 300px;
}

.minwidth400 {
    min-width: 400px;
}

.minwidth500 {
    min-width: 500px;
}

.minwidth600 {
    min-width: 600px;
}

.minwidth700 {
    min-width: 700px;
}

.minwidth800 {
    min-width: 800px;
}

.maxwidth50 {
    max-width: 50px;
}

.maxwidth100 {
    max-width: 100px;
}

.maxwidth150 {
    max-width: 150px;
}

.maxwidth200 {
    max-width: 200px;
}

.maxwidth300 {
    max-width: 300px;
}

.maxwidth400 {
    max-width: 400px;
}

.maxwidth500 {
    max-width: 500px;
}

.maxwidth600 {
    max-width: 600px;
}

.maxwidth700 {
    max-width: 700px;
}

.maxwidth800 {
    max-width: 800px;
}

.NoVerticalScroll {
    overflow-y: hidden !important;
}

.modal-lg {
    max-width: 80% !important;
}

.row-nopadding {
    padding-top: 0px;
    padding-bottom: 0px;
}

.row-paddingtop {
    padding-top: 5px;
    padding-bottom: 0px;
}

.row-padding {
    padding-top: 5px;
    padding-bottom: 5px;
}

.row-padding-lg {
    padding-top: 10px;
    padding-bottom: 10px;
}

.form-padding-5 {
    padding: 5px;
}

.form-padding-6 {
    padding: 6px;
}

.radiobutton {
    margin-left: 0px !important;
    margin-top: 0px !important;
    position: relative !important;
}

.radiobuttontext {
    margin-left: 5px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.table {
    margin-bottom: 0px;
}

.table-center {
    text-align: -webkit-center !important;
    text-align: -moz-center !important;
}

.datePickerWraper {
    position: inherit !important;
}

.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
    background-color: $palette-primary-9;
}

.mt-1 {
    margin-left: 0.5rem !important;
}

.mt-3 {
    margin-left: 7rem !important;
}

.mt40 {
    margin-top: 40px;
}

.mt50 {
    margin-top: 50px;
}

.mt30 {
    margin-top: 30px;
}

.mt20 {
    margin-top: 20px;
}

.mt10 {
    margin-top: 10px;
}

.mt5 {
    margin-top: 5px;
}

.mb20 {
    margin-bottom: 20px;
}

.aMScolor {
    color: #1173b0;
}

.dropdown-header {
    color: $deferral-text-menu;
}

.dropdowns-padding {
    padding: 10px;
}

.tab-pane.active {
    padding: 0px;
}

.bootstrapTable-center {
    align-self: center;
}

// .react-bootstrap-table-pagination {
//   padding-top: 15px;
// }

.rows-per-page-text {
    margin-top: 7px;
}

.rows-per-page-select {
    height: 27px;
    margin-top: 5px;
}

.col-span {
    padding-left: 15px;
    padding-right: 15px;
}

.quilldisplayborder {
    border-style: solid;
    border-width: 1px;
}

.quillspace {
    padding-bottom: 25px;
}
.frmLabel {
    font-size: 10pt;
    //font-family: Verdana;
    color: black;
    font-style: normal;
    text-align: right;
}

.summary-title {
    text-transform: uppercase;
    font-size: 1rem !important;
    &.active {
        color: $deferral-background !important;
    }
}

.afsMessageAlert {
    color: $palette-button-cancel;
}

.form-control {
    display: inline-block;
    padding: 0px;
    height: 100%;
}

.form-control-label {
    margin-bottom: 0px;
    padding-bottom: 5px;
}

.summary-table {
    align-self: center;
}

.table-white {
    background-color: $deferral-background;
}

.table-fitcontent {
    //align-self: left;
    width: -moz-fit-content;
    width: fit-content;
}

.row-center {
    justify-content: center;
}

.row-left {
    justify-content: left;
}

.row-right {
    justify-content: right;
}

.subMenuButton {
    border: 0px;
}
.subMenuButtonDiv {
    border: 0px;
}
.title-button {
    text-align: -webkit-center;
    color: $deferral-text-menu;
    background-color: transparent;
    margin: 0px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    justify-content: center;
    &.active {
        color: $topmenu-active-text;
    }
}

.ql-editor {
    background-color: $deferral-background;
    min-height: 50px;
    padding: 0px !important;
    a {
        text-decoration: underline !important; /*Deferral: none;*/
    }
}

// .ql-editor a {
//   text-decoration: underline !important; /*Deferral: none;*/
// }

.message-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-bottom: 6px;
}

.logout-image {
    background: $navbar-brand-minimized-logout-image;
    height: 423px;
    width: 540px;
    align-self: center;
}

.logout-table {
    height: 423px;
    width: 540px;
    align-self: center;
}

.app-logout {
    background: $navbar-brand-minimized-logout-image;
    background-color: $deferral-background;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

$border-color-nav-botton: $gray-200;
$navbar-brand-bg: $gray-200;
$navbar-brand-logo-size: 255px auto;
$navbar-color: $gray-200;
$navbar-hover-color: darken($navbar-color, 0%);
$navbar-active-color: darken($navbar-color, 0%);
$navbar-disabled-color: lighter($navbar-color, 0%);
$sidebar-bg: #ffffff; //deferral #BDBDBD;
$sidebar-nav-title-color: $gray-900;
$sidebar-nav-link-hover-bg: darken($sidebar-bg, 0%);
$sidebar-nav-link-active-bg: darken($sidebar-bg, 0%);
$sidebar-nav-dropdown-bg: darken($sidebar-bg, 0%);

$navbar-color: $gray-800 !default;
$navbar-hover-color: $gray-900 !default;
$navbar-active-color: $gray-900 !default;
$sidebar-minimized-width: 59px !default; // deferral 50px !default;
$sidebar-color: $gray-800; // deferral #fff !default;
$sidebar-nav-color: $gray-800; //deferral #fff !default;
$sidebar-nav-link-color: $gray-800 !default; //deferral #fff !default;
$sidebar-nav-link-icon-color: $gray-800 !default;
$sidebar-nav-link-hover-color: $gray-900 !default; //deferral #fff !default;
$sidebar-nav-link-hover-icon-color: $gray-900 !default; //deferral #fff !default;
$sidebar-nav-link-active-color: $gray-900 !default;
$sidebar-nav-dropdown-color: $gray-800; // deferral #fff !default;
$sidebar-nav-dropdown-indicator-color: $gray-800 !default;
//Deferredcomp.morganstanleyatwork.com - $footer-bg:                           $gray-100 !default;
$blue_nav-bar: #1985ac;

.app-header.navbar {
    background-color: $deferral-background;
    border-bottom-color: $deferral-button;
    top: 0px;
}

.app-header.navbar .navbar-brand {
    background-color: $deferral-background;
    border-bottom-color: $deferral-button;
}

.card {
    border: 0px;
}

.tableBorder {
    border: 1px solid gray;
}

@include media-breakpoint-up(lg) {
    .sidebar-minimized {
        .hidden-cn {
            display: inherit; //none;
        }
    }
    .brand-minimized {
        .app-header.navbar {
            .navbar-brand {
                width: $navbar-brand-width;
                background-color: $navbar-brand-bg;
                // background-image: $navbar-brand-logo;
                background-size: $navbar-brand-logo-size;
                @include borders($navbar-brand-border);
            }
        }
    }
}

.headerSerch {
    display: none;
    top: 0;
    position: relative;
    float: right;
}

a {
    color: $deferral-text2;
    &:hover {
        cursor: pointer;
    }
}

//.nav {
//font-size: larger;  /* Deferral.com - New */
//}

.logoPowerby {
    width: auto;
}

.logoFooter {
    width: 250px;
    object-fit: none;
    background-image: none !important;
    background-color: transparent !important;
}

.ms-header-logo {
    content: $ms-logo-lg;
    visibility: visible;
    display: flex;
    margin-left: 40px;
    width: auto;
    height: 40px;
}

.ms-header-sit-logo {
    content: $ms-env-sit-lg;
    visibility: visible;
    display: flex;
    width: auto;
    height: 40px;
}

.ms-header-uat-logo {
    content: $ms-env-uat-lg;
    visibility: visible;
    display: flex;
    width: auto;
    height: 40px;
}

.ms-header-qa-logo {
    content: $ms-env-qa-lg;
    visibility: visible;
    display: flex;
    width: auto;
    height: 40px;
}

.privacyoptions {
    height: 12px;
    margin-bottom: 1px;
}

.privacyoptions {
    height: 12px;
}

.ms-header-logo2 {
    visibility: visible;
    display: flex;
    margin-right: 40px;
    align-self: right;
    height: 40px;
    margin-left: auto;
}

.nav-link-text {
    padding: 0.5rem 1rem; /* Deferral - New */
}

.nav-link:hover,
.navbar .dropdown-toggle:hover,
.nav-link:focus,
.navbar .dropdown-toggle:focus {
    text-decoration: underline; /*Deferral: none;*/
}

.nav-link-2 {
    padding-left: 0px; /*Deferral: 0.33594rem;*/
    padding-right: 0px;
    color: $deferral-text !important;
    &:hover {
        text-decoration: underline; /*Deferral: none;*/
        cursor: pointer;
    }
}

.navbar .dropdown-toggle:hover,
.nav-link:focus,
.navbar .dropdown-toggle:focus {
    text-decoration: underline; /*Deferral: none;*/
    padding-left: 0px; /*Deferral: 0.33594rem;*/
    padding-right: 0px;
}

.nav-tabs .nav-link,
.nav-tabs .navbar .dropdown-toggle,
.navbar .nav-tabs .dropdown-toggle {
    border: 1px solid #c0cadd; /*Deferral: 1px solid transparent;*/
}

.navbar-brand {
    //padding-top: 1px !important; /*Deferral: 0.33594rem;*/
    //padding-bottom: 1px !important; /*Deferral: 0.33594rem;*/
    background-image: none !important;
    height: 56px !important;
    width: 256px !important;
    object-fit: none;
    padding: 0px !important;
    //padding-bottom: 0px !important;
    //border-top-width: 1px !important;
    background-color: transparent !important;
    border: none !important;
    &.alt-text {
        display: inline !important;
    }
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .navbar .dropdown-toggle:hover,
.navbar .navbar-light .navbar-nav .dropdown-toggle:hover,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .navbar .dropdown-toggle:focus,
.navbar .navbar-light .navbar-nav .dropdown-toggle:focus {
    color: rgba(120, 120, 120, 0.7); /* Deferral : rgba(0, 0, 0, 0.7); */
}

.page-link,
.pagination-datatables li a,
.pagination li a {
    color: #384042; /*Deferral: #20a8d8;*/
}

.nav-tabs .nav-link,
.nav-tabs .navbar .dropdown-toggle,
.navbar .nav-tabs .dropdown-toggle {
    color: #646464; /*Deferral:#9faecb;*/
}

.app-header.navbar .navbar-brand {
    background-size: 250px auto; /*Deferral: 90px auto;*/
    border-bottom: 1px solid #1985ac; /*Deferral: border-bottom: 1px solid #1985ac;*/
}

.sidebar {
    background: $deferral-background; /* Deferral: #384042; */
    color: #3b3b3b; /* Deferral: #384042; */
}

.sidebar .sidebar-close {
    background: #bdbdbd; /* Deferral: 0; */
}

.sidebar .sidebar-header {
    padding: 0.5rem 1rem; /* Deferral - New */
    background: #bdbdbd; /* Deferral: rgba(0, 0, 0, 0.2); */
}

.sidebar .sidebar-form .form-control {
    background: #bdbdbd; /* Deferral: #212526; */
}

.sidebar .sidebar-nav::-webkit-scrollbar-track {
    background-color: #bdbdbd; /* Deferral: #444d50; */
}

.sidebar .sidebar-nav::-webkit-scrollbar-thumb {
    background-color: #bdbdbd; /* Deferral: #212526; */
}

.sidebar .nav-title {
    font-size: 14px; /* Deferral: 11px; */
    color: #384042; /* Deferral: #9faecb; */
}

.sidebar .nav-item {
    margin: 0; /* Deferral: margin: 0;*/
}

.sidebar .nav-link,
.sidebar .navbar .dropdown-toggle,
.navbar .sidebar .dropdown-toggle {
    padding-bottom: 3px; /* Deferral: 0.75rem 1rem; */
    padding-top: 5px;
}

.sidebar .nav-dropdown.open .nav-link {
    color: $deferral-text-menu;
}

//

.sidebar .nav-dropdown-items {
    padding-bottom: 5px;
}
.sidebar .nav-link i,
.sidebar .navbar .dropdown-toggle i,
.navbar .sidebar .dropdown-toggle i {
    color: $deferral-menu;
}

.sidebar .nav-link.active,
.sidebar .navbar .active.dropdown-toggle,
.navbar .sidebar .active.dropdown-toggle {
    background: $deferral-background-2;
}

.sidebar .nav-link.active,
.sidebar .navbar .active.dropdown-toggle,
.navbar .sidebar .active.dropdown-toggle {
    background: #bdbdbd;
}

.sidebar .nav-link:hover,
.sidebar .navbar .dropdown-toggle:hover,
.navbar .sidebar .dropdown-toggle:hover {
    color: $deferral-menu; /* Hover Over Right Menu*/
    background: $deferral-background;
}

.sidebar .nav-link:hover i,
.sidebar .navbar .dropdown-toggle:hover i,
.navbar .sidebar .dropdown-toggle:hover i {
    color: $deferral-menu;
}

.sidebar .nav-link.nav-link-primary,
.sidebar .navbar .nav-link-primary.dropdown-toggle,
.navbar .sidebar .nav-link-primary.dropdown-toggle {
    background: #bdbdbd; /* Deferral: #20a8d8; */
}

.sidebar .nav-link.nav-link-primary:hover,
.sidebar .navbar .nav-link-primary.dropdown-toggle:hover,
.navbar .sidebar .nav-link-primary.dropdown-toggle:hover {
    background: #bdbdbd; /* Deferral: #1d97c2 !important; */
}

.sidebar .nav-dropdown.open {
    background: $deferral-background; /* Deferral: #31383a; */
}

.nav-link {
    padding-left: 0px;
}

.sidebar .nav-label {
    color: $deferral-text-menu;
    margin-left: 5px;
    margin-bottom: 0px;
    padding-left: 15px;
    padding-bottom: 2px;
    padding-top: 2px;
}

.nav-label {
    padding-left: 0px;
}

.nav-label-home {
    padding-left: 3px !important;
    border-bottom-width: 1px !important;
    border-bottom-style: solid !important;
    margin-bottom: 10px !important;
    margin-top: 0px !important;
}

.sidebar .progress {
    background-color: #bdbdbd; /* Deferral: #5b686b !important; */
}

.sidebar .sidebar-footer {
    background: #bdbdbd; /* Deferral: rgba(0, 0, 0, 0.2); */
}

.sidebar .sidebar-minimizer {
    background-color: #bdbdbd; /* Deferral: rgba(0, 0, 0, 0.2); */
}

.sidebar-minimized .sidebar .nav-item:hover > .nav-link,
.sidebar-minimized .sidebar .navbar .nav-item:hover > .dropdown-toggle,
.navbar .sidebar-minimized .sidebar .nav-item:hover > .dropdown-toggle {
    background: #bdbdbd; /*Deferral: #2c3334;*/
}

.sidebar-minimized .sidebar .nav-link:hover,
.sidebar-minimized .sidebar .navbar .dropdown-toggle:hover,
.navbar .sidebar-minimized .sidebar .dropdown-toggle:hover {
    background: #bdbdbd; /*Deferredcomp.morganstanleyatwork.com: background: #2c3334;*/
}

.sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items {
    background: #bdbdbd; /*Deferral: background: #384042;*/
}

.sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
    background: #bdbdbd; /*Deferral: background: #2c3334;*/
}

/* Deferredcomp.morganstanleyatwork.com Custom Styles */

.nav-margin {
    padding-left: 24px;
    min-width: -webkit-fill-available;
}

.nav-margin-2 {
    padding-left: 48px;
    min-width: -webkit-fill-available;
}

.nav-margin-3 {
    padding-left: 72px;
    min-width: -webkit-fill-available;
}

.nav-margin-4 {
    padding-left: 96px;
    min-width: -webkit-fill-available;
}

.card-borderless {
    border: 0;
    box-shadow: none;
}

.performanceFont {
    font-size: 0.7rem !important;
}

.radiobutton-padding {
    padding-left: 30px;
}

.checkbox-padding {
    padding-left: 35px;
    margin-bottom: 30px;
}

.message-link {
    color: $deferral-text2 !important;
    font-size: 0.7rem !important;
    padding-bottom: 2px;
    text-align: right !important;
    &:hover {
        cursor: pointer;
    }
}

.message-link-font-ms {
    font-size: 13px !important;
    &:hover {
        text-decoration: underline !important;
        color: #167495 !important;
    }
}

.message-link2 {
    color: $deferral-text2 !important;
    font-size: 0.8rem !important;
    padding-bottom: 2px;
    text-align: right !important;
    &:hover {
        cursor: pointer;
    }
}

//DEFERRAL CLASS

.icon-button {
    font-size: 2rem !important;
    padding-bottom: 1px !important;
    &.active {
        color: $deferral-background;
    }
}

.icon-button-admin {
    font-size: 1.5rem !important;
    padding-bottom: 1px !important;
    &.active {
        color: $topmenu-active-text;
    }
}

.icon-button-menu {
    font-size: 1.3rem !important;
    width: 1.3rem !important;
    padding-right: 5px;
}

.icon-button-submenu {
    font-size: 1.15rem !important;
    width: 1.3rem !important;
    padding-right: 5px;
}

.icon-button-submenu-right {
    font-size: 1.5rem !important;
    padding-right: 5px;
}

.icon-hiden {
    font-size: 1rem !important;
    padding-bottom: 1px !important;
    align-content: flex-end;
    //&.active{
    // color: $deferral-background;
    //}
}

.card-hide {
    border: 0px;
    text-align: right;
}

.card-button {
    border: none;
    padding-top: 3px;
    & .card-header {
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer;
    }
}

.subform-table {
    align-self: start !important;
}

.message-NoPadding {
    padding: 0px !important;
    margin: 0px !important;
}

.inputHeightSm {
    height: 23px !important;
}

.buttonSm {
    border-width: 1px;
    border-color: $deferral-button;
    color: $deferral-button;
    background: $deferral-background;
    border-radius: 5px;
    align-self: flex-end;
    padding: 0px 5px 0px 5px;
    margin: 0px 5px 0px 5px;
    height: 24px;
    &:hover {
        color: $deferral-background !important;
        background-color: $deferral-button !important;
    }
}

.buttonSm-delete {
    border-width: 1px;
    border-color: $deferral-button-cancel;
    color: $deferral-button-cancel;
    background: $deferral-background;
    border-radius: 5px;
    align-self: flex-end;
    padding: 0px 5px 0px 5px;
    margin: 0px 5px 0px 5px;
    height: 24px;
    &:hover {
        color: $deferral-background !important;
        background-color: $deferral-button-cancel !important;
    }
}

.title-center {
    align-self: center;
    text-align: center !important;
}

.home-button2 {
    &.active {
        background-color: $deferral-button !important;
        .nav-label {
            color: $deferral-background;
        }
    }
    &:hover {
        background-color: $deferral-button !important;
        .nav-label {
            color: $deferral-background;
        }
    }
}

.column-margin {
    margin-right: 20px;
}

.username-class {
    margin-right: 5px;
    margin-top: 13px;
}

.nav-item {
    border-radius: 5px;
    border-width: 1px;
    border-color: $deferral-border;
    border: thick;
    border-bottom: none;
    background-color: $deferral-background !important;
    &.active {
        color: $deferral-background;
        background-color: $deferral-border;
    }
}

.d-flex {
    padding-left: 1px;
    padding-right: 1px;
}

.no-Click {
    opacity: 0.6;
    pointer-events: none;
}

.btn.disabled {
    //opacity: 1;
    background-color: $palette-gray;
    border-color: $palette-gray;
    // opacity: 0.5;
    // &.hover {
    //   background-color: $palette-gray;
    //   border-color: $palette-gray;
    //   opacity: 0.5;
    // }
}

// .btn-primary:focus {
//   background-color: $palette-gray;
//   border-color: $palette-gray;
//   opacity: 0.5;
// }
// .btn-primary:active {
//   background-color: $palette-gray;
//   border-color: $palette-gray;
//   opacity: 0.5;
// }

.submenu-font {
    font-size: $submenu-font-size !important;
}

.top-menu {
    font-size: $top-menu-font-size;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    border-width: 1px;
    border-color: $deferral-button;
    border-style: solid;
    background-color: $deferral-background !important;
    &.active {
        color: $topmenu-active-text !important;
        background-color: $topmenu-active-background !important;
    }
}

.card-button {
    background-color: transparent;
}

.btn-filter {
    border-radius: 5px;
    font-size: 1rem;
    margin-top: 0.175rem !important;
    border-color: $deferral-button !important;
    color: $deferral-button !important;
    background-color: $deferral-background !important;
    box-shadow: none !important;
    &.active {
        color: $deferral-background !important;
        background-color: $deferral-button !important;
    }
}

.text-blue-strong {
    color: #5f07ff;
    font-size: 10pt;
}

.text-ms-header {
    color: #000000;
    font-size: 50pt !important;
    text-align: center;
}

.modal-width {
    max-width: 50% !important;
}

.modal-small-width {
    max-width: 100%;
}

.text-valuesInfo {
    color: $deferral-enrollment-helptext;
    font-size: 11px;
}

.card-deferralElections {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.card {
    margin-bottom: 0px !important;
}

.min-width-sm {
    min-width: 70px;
}

.min-width-md {
    min-width: 110px;
}

.min-width-lg {
    min-width: 150px;
}

.input-group-text {
    display: flow-root;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    border: none;
    background-color: $palette-primary-9;
    font-size: 0.8rem;
}

.input-group-prepend {
    display: inline-block;
    background-color: $palette-primary-dark-7;
    //background-color: $palette-primary-9;
    border-width: 1px;
    border-color: $palette-border;
    border-style: solid;
}

.text-input-error {
    text-align: end;
}

.multipleselect-title-line {
    margin-bottom: 0px;
    margin-left: 30px;
    margin-right: 30px;
    border-top: solid;
    border-top-color: $palette-primary-dark-6;
    border-top-width: 1px;
    margin-top: 20px;
    padding-top: 5px;
}

.col-frame {
    border-style: none;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}

.noSidePadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.noTopPadding {
    padding-top: 0 !important;
}

.noBottomPadding {
    padding-bottom: 0 !important;
}

.TopPadding20 {
    padding-top: 20px !important;
}

.col-padding-right {
    padding-left: 0;
}

.col-padding-left {
    padding-right: 0;
}

.deferral-label {
    text-align: right;
}

.deferral-field {
    text-align: left;
}

.headerLabel-ms {
    font-size: 100px;
}

.deferral-label2 {
    margin-top: 10px;
    text-align: left;
    color: black;
    font-size: 13px;
    font-family: graphik;
    height: 20px;
    font-weight: 400;
    line-height: 18px;
    //TODO
}

.deferral-field2 {
    text-align: left;
    color: black;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    font-family: graphik;
    height: 30px;
    border: 1px solid;
    padding-left: 5px;
    padding-right: 5px;
}

.ms-header {
    color: #000000;
    font-size: 26px;
    font-weight: 500;
    margin-top: 10px;
    letter-spacing: -0.4px;
    line-height: 34px;
}

.ms-footer-body {
    width: 150%;
    background: #f6f6f6;
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 18px;
    color: black;
    font-size: 13px;
}
.ms-footer-version {
    width: 150%;
    //margin: 0px;
    background: white;
    height: 30px;
    line-height: 18px;
    color: black;
    font-size: 13px;
}

.ms-divider {
    border-left: 2px solid black;
    height: 80%;
    margin-top: 40px;
}

.ms-subheader {
    color: #000000;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: -0.4px;
    line-height: 34px;
}

.ms-text {
    color: #000000;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
}

.clearDateButton {
    display: none !important;
}

.inputDate {
    width: 85px !important;
    flex: none !important;
}

.inputXl {
    width: 200px !important;
    flex: none !important;
}

.inputLg {
    width: 150px !important;
    flex: none !important;
}

.inputMd {
    width: 100px !important;
    flex: none !important;
}

.inputMdIcon {
    width: 120px !important;
    flex: none !important;
}

.inputDate {
    width: 85px !important;
    flex: none !important;
}

.inputSm {
    width: 50px !important;
    flex: none !important;
}

.inputSmIcon {
    width: 70px !important;
    flex: none !important;
}

.selectSm {
    width: 50% !important;
    flex: none !important;
}

.selectSm2 {
    margin-left: 50% !important;
    flex: none !important;
}

.selectMd {
    width: 75% !important;
    flex: none !important;
}

.selectMdsm {
    width: 85% !important;
    flex: none !important;
}

.selectMd2 {
    margin-left: 15% !important;
    flex: none !important;
}

.selectLg {
    width: 100% !important;
    flex: none !important;
}

.multipleSelectLg {
    width: 100% !important;
    overflow: auto !important;
}

.inputNumber {
    text-align: right;
}

.inputNumber2 {
    text-align: right;
    margin-left: 0px !important;
}

.inputText {
    text-align: left;
}

.row-padding-m10 {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 10px;
}

.row-padding-m30 {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 30px;
}

.row-input {
    margin-top: 0px;
    margin-left: 0px;
}

.row-input2 {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.td-col-2 {
    width: 17%;
}

.td-col-4 {
    width: 33%;
}

.td-col-6 {
    width: 50%;
}

.td-col-8 {
    width: 67%;
}

.td-col-10 {
    width: 83%;
}

.checkbox {
    margin-left: 0px;
    margin-top: 0px;
    position: relative !important;
}

.radio-input {
    margin-left: 0px;
    position: relative !important;
}

.input-group {
    flex-wrap: nowrap;
}

.input-icon {
    white-space: nowrap;
}

.wrapText {
    white-space: pre-wrap;
}

.sort-icon {
    color: $deferral-grid-title-sort !important;
    font-size: 1rem !important;
}

.iconNoBackground {
    background-color: transparent;
    border-style: none;
}

.text-gray-400 {
    padding-left: 10px;
}

.select-complementary-margin {
    //padding-left: 10px;
    padding: 0px 5px 0px 5px;
}

.checklabel {
    padding-left: 10px;
}

.nowrap {
    white-space: nowrap;
}

.displayFlex {
    display: flex;
}

.inlineflex {
    display: inline-flex;
}

//@-moz-document url-prefix() {
@supports (-moz-appearance: none) {
    .inlineflex {
        display: ruby;
    }
}

.maxcontent {
    max-width: -moz-max-content;
    max-width: max-content;
}

.minmaxcontentr10 {
    min-width: -moz-max-content;
    min-width: max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    margin-right: 10px;
}

.minmaxcontentr15 {
    min-width: -moz-max-content;
    min-width: max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    //margin-right: 15px;
    padding: 0px !important;
}

.minmaxcontent {
    min-width: -moz-max-content;
    min-width: max-content;
    max-width: -moz-max-content;
    max-width: max-content;
}

.btn-summary {
    border-radius: 5px;
    font-size: 1rem;
    border-color: $deferral-button !important;
    color: $deferral-button !important;
    background-color: $deferral-background !important;
    box-shadow: none !important;
    &.active {
        color: $deferral-background !important;
        background-color: $deferral-button !important;
    }
}

.btn-gridcontrolEdit {
    border-radius: 5px;
    font-size: 0.8rem;
    border-color: $deferral-button;
    color: $deferral-button;
    background-color: $deferral-background;
    box-shadow: none !important;
    padding: 0px 5px 2px 5px;
    &.active {
        color: $deferral-background !important;
        background-color: $deferral-button-cancel !important;
    }
}

.btn-gridcontrolDelete {
    border-radius: 5px;
    font-size: 0.8rem;
    border-color: $deferral-button-cancel;
    color: $deferral-button-cancel;
    background-color: $deferral-background;
    box-shadow: none !important;
    padding: 0px 5px 2px 5px;
    &.active {
        color: $deferral-background !important;
        background-color: $deferral-button-cancel !important;
    }
}

.app-footer {
    //height: 56px;
    padding-top: 0px;
    background-color: $deferral-background-footer;
    //position: fixed;
    bottom: 0px;
}

.confirmation-title {
    font-size: x-large;
}

.btn-fit {
    min-width: -moz-max-content !important;
    min-width: max-content !important;
}

.btn-next {
    border-width: 1px;
    min-width: 80px;
    //min-width: max-content;
    //min-width: -moz-max-content;
    border-color: $deferral-button;
    color: $deferral-button;
    background: $deferral-button-bck;
    border-radius: 5px;
    align-self: flex-end;
    padding: 5px 10px 5px 10px;
    margin: 0px 10px 0px 10px;
    &:hover {
        background-color: $deferral-button !important;
        color: $deferral-background !important;
    }
}

.btn-center {
    align-self: center !important;
}

.btn-save {
    border-width: 1px;
    border-color: $deferral-button-save;
    min-width: 80px;
    background: $deferral-button-save-bck;
    color: $deferral-button-save;
    border-radius: 5px;
    align-self: flex-end;
    padding: 5px 10px 5px 10px;
    margin: 0px 10px 0px 10px;
    &:hover {
        color: $deferral-background !important;
        background-color: $deferral-button-save !important;
    }
}
.ms-button-container {
    text-align: center;
    align-self: center;
    margin: auto;
}

.btn-save-ms {
    text-align: center;
    align-self: center;
    //border-width: 1px;
    border: none;
    border-color: $ms-button-save;
    min-width: 80px;
    background: $ms-button-save;
    color: white;

    border-radius: 40px !important;
    //align-self: flex-end;
    padding: 5px 10px 5px 10px;
    margin: 20px 10px 0px 10px;
    &:hover {
        color: $deferral-background !important;
        background-color: #002b51 !important;
    }
}
.forgot-pw-container {
    display: flex;
    justify-content: center;
    align-items: center;
    //height: 100vh;
}

.ms-logon-btn.btn-save-ms {
    border-radius: 40px !important;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
}
.btn-cancel-ms {
    box-shadow: none !important;
    border-width: 0px;
    border-color: #ffffff;
    background: #ffffff;
    //min-width: 80px;
    color: #187aba;
    //border-radius: 20px !important;
    //align-self: flex-end;
    padding: 0px;
    margin: 0px;
    &:hover {
        color: #187aba !important;
        background-color: #ffffff !important;
        text-decoration: underline;
        cursor: pointer;
    }
    &.active {
        box-shadow: none !important;
    }
    &:focus {
        box-shadow: none;
        color: #187aba !important;
        background-color: #ffffff !important;
        text-decoration: underline;
    }
}

.btn-cancel {
    border-width: 1px;
    border-color: $deferral-button-cancel;
    background: $deferral-button-cancel-bck;
    min-width: 80px;
    color: $deferral-button-cancel;
    border-radius: 5px;
    align-self: flex-end;
    padding: 5px 10px 5px 10px;
    margin: 0px 10px 0px 10px;
    &:hover {
        color: $deferral-background !important;
        background-color: $deferral-button-cancel !important;
    }
}

.mainMenuControl {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-bottom: 5px !important;
    margin-top: 0px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
    border-radius: 0;
}

.nav-line {
    &.active {
        color: $deferral-background !important;
        background-color: $deferral-button !important;
    }
}

.top-nav {
    display: flex !important;
    padding-left: 0px;
    padding-right: 0px;
}

.questionnaire-answer {
    border-style: none;
    width: auto;
}

.questionnaire-answer tr td {
    border-style: none !important;
}

.questionnaire-table tr td {
    padding-right: 5px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.btn-submenu {
    text-align: left;
    margin: 0px !important;
    padding: 0px !important;
    font-size: medium;
    font-weight: 600;
    box-shadow: none !important;
    &:hover {
        text-decoration: underline; /*Deferral: none;*/
        cursor: pointer;
    }
}

.btn-secondary {
    margin-bottom: 5px;
}

.pagination-wrapper {
    overflow-x: auto;
}

.pagination {
    justify-content: center;
}

.row-pagination {
    // text-align: -webkit-center;
    margin: 10px;
    justify-content: center;
}

.col-pagination {
    padding-top: 5px;
    padding-bottom: 5px;
}

.data-slice {
    margin-top: 10px;
}

.qrcode-container {
    margin-top: 40px;
}

.qrcode {
    margin: 0 auto;
    max-width: 10px;
    width: 100%;
}

.qrcode canvas {
    height: 100% !important;
    width: 100% !important;
}

h1 {
    font-size: 80px;
    text-align: center;
}

.mfa-form {
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    background-color: initial !important;
}

.mfa-Options {
    width: 100%;
    margin: 0 auto;
    max-width: 350px;
}

.mfa-input {
    width: 100px !important;
    flex: none !important;
    //font-family: Verdana;
    color: black;
    font-size: 14px;
    padding: 12px 10px;
    box-sizing: border-box;
    border: 1px solid black;
    height: 35px;
    border-radius: 2px;
}

.mfa-label {
    padding: 0px;
}

.mfa-radio {
    //padding-top: 20px !important;
    padding-bottom: 10px !important;
}

.errorMsgRed {
    font-size: 15px;
    margin-top: 15px;
    text-align: center;
    color: red;
}

.errorMsgYellow {
    font-size: 15px;
    margin-top: 15px;
    text-align: center;
    color: goldenrod;
}

.warningMsgGreen {
    font-size: 15px;
    text-align: center;
    color: green;
}

.check-safe {
    padding: 0 !important;
    margin-left: 0.5 !important;
    margin-top: 10px;
}

.btn-token {
    margin-top: 10px !important;
    width: 100%;
}

.table-center {
    text-align: center;
}
.text-normal {
    font-weight: normal;
}
.text-bold {
    font-weight: bold;
}
.button-margin {
    margin-left: 23px;
}
.text-margin-50 {
    margin-left: 50px !important;
}
.text-margin-80 {
    margin-left: 80px !important;
}
.modal-width {
    max-width: 50% !important;
}
.text-margin-radio {
    margin-left: 60px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
}
.margin-inherit {
    margin-left: inherit;
}
.modal-small-width {
    max-width: 100%;
    .input-group {
        flex-wrap: nowrap;
    }
}

.user-update-form {
    margin: 0 auto;
    max-width: 700px;
    width: 100%;
}

.input-user {
    margin-left: 18px;
}

.changeUser-label {
    text-align: center;
    font-weight: 500;
}

.changeUser-field {
    text-align: right !important;
    font-weight: 550;
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
}
.spaces-btw-lines {
    margin-top: 10px;
    margin-bottom: 10px;
}

.row-field-margin {
    margin-left: 6px !important;
}

.test-gridgropu-margin {
    margin-bottom: 2px !important;
}

.justify-content {
    display: flex;
    justify-content: space-between;
}

.info-margin {
    margin-left: 35px;
}

.gridgroup-padding {
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 0px;
    padding-right: 0px;
}

.bg-darkblue {
    color: $deferral-grid-title-text;
    background-color: $deferral-grid-title-bkg;
}

@include media-breakpoint-down(md) {
    .ms-header-logo {
        content: $ms-logo-lg;
        visibility: visible;
        display: flex;
        margin-left: 40px;
        width: auto;
        height: 40px;
    }

    .ms-header-sit-logo {
        content: $ms-env-sit-lg;
        visibility: visible;
        display: flex;
        width: auto;
        height: 40px;
    }

    .ms-header-uat-logo {
        content: $ms-env-uat-lg;
        visibility: visible;
        display: flex;
        width: auto;
        height: 40px;
    }

    .ms-header-qa-logo {
        content: $ms-env-qa-lg;
        visibility: visible;
        display: flex;
        width: auto;
        height: 40px;
    }

    .ms-header-logo2 {
        margin-right: 20px;
        max-width: 280px;
    }
    .app-header.navbar {
        background-color: $deferral-background;
        align-items: center;
    }
    .icon-button {
        font-size: 1rem !important;
        padding-bottom: 1px !important;
        &.active {
            color: $deferral-background;
        }
    }
    .icon-button-admin {
        font-size: 1.5rem !important;
        padding-bottom: 1px !important;
        &.active {
            color: $deferral-background;
        }
    }
    .card-header {
        padding-top: 3px;
        padding-bottom: 0px;
    }
    .main .container-fluid {
        padding-left: 0px;
        padding-right: 0px;
    }
    .deferral-field22 {
        text-align: left;
        font-size: 11px;
        font-family: graphik;
        width: 50%;
        //width: 50px !important;
        //padding-left: 50px;
        //margin-right: 50px;
    }
}

@include media-breakpoint-down(sm) {
    //.app-header.navbar {
    //justify-content: center;
    //}
    .ms-header-logo {
        content: $ms-logo-sm;
        visibility: visible;
        display: flex;
        margin-left: 20px;
        width: auto;
        height: 40px;
    }

    .ms-header-sit-logo {
        content: $ms-env-sit-sm;
        visibility: visible;
        display: flex;
        width: auto;
        height: 40px;
    }
    .ms-header-uat-logo {
        content: $ms-env-uat-sm;
        visibility: visible;
        display: flex;
        width: auto;
        height: 40px;
    }

    .ms-header-qa-logo {
        content: $ms-env-qa-sm;
        visibility: visible;
        display: flex;
        width: auto;
        height: 40px;
    }
    .ms-header-logo2 {
        margin-right: 20px;
        max-width: 220px;
    }
    .title-button {
        & .title-text {
            visibility: hidden;
            width: 0px;
            height: 0px;
        }
    }
    .top-nav {
        display: -ms-grid;
        display: grid !important;
    }
    .card-header {
        padding-left: 0px;
        padding-right: 0px;
    }
    .icon-button {
        font-size: 2rem !important;
    }
    .icon-button-admin {
        font-size: 1.5rem !important;
    }
    .deferral-field22 {
        text-align: left;
        font-size: 11px;
        font-family: graphik;
        width: 90%;
        //padding-left: 50px;
        //margin-right: 50px;
    }
}

@include media-breakpoint-down(xs) {
    .app-header.navbar {
        justify-content: center;
    }
    .ms-header-logo {
        content: $ms-logo-sm;
        visibility: visible;
        display: flex;
        margin-left: 0px;
        width: auto;
        height: 40px;
    }

    .ms-header-sit-logo {
        content: $ms-env-sit-sm;
        visibility: visible;
        display: flex;
        width: auto;
        height: 40px;
    }

    .ms-header-uat-logo {
        content: $ms-env-uat-sm;
        visibility: visible;
        display: flex;
        width: auto;
        height: 40px;
    }

    .ms-header-qa-logo {
        content: $ms-env-qa-sm;
        visibility: visible;
        display: flex;
        width: auto;
        height: 40px;
    }

    .ms-header-logo2 {
        visibility: hidden;
        display: none;
        height: 0px;
        width: 0px;
    }
    .title-button {
        & .title-text {
            visibility: hidden;
            width: 0px;
            height: 0px;
        }
    }

    .top-nav {
        display: -ms-grid;
        display: grid !important;
    }

    .form-text {
        margin-top: -10px;
    }

    .card-header {
        padding-left: 0px;
        padding-right: 0px;
    }
    .icon-button {
        font-size: 2rem !important;
    }
    .icon-button-admin {
        font-size: 1.5rem !important;
    }
    .deferral-field22 {
        text-align: left;
        font-size: 11px;
        font-family: graphik;
        width: 90%;
        //padding-left: 50px;
        //margin-right: 50px;
    }
}
@media screen and (max-width: 1900px) {
    .deferral-field22 {
        background-color: red;
        text-align: left;
        font-size: 11px;
        font-family: graphik;
        width: 50% !important;
        //padding-left: 50px;
        //margin-right: 50px;
    }
}
@media screen and (max-width: 840px) {
    .deferral-field22 {
        background-color: yellow;
        text-align: left;
        font-size: 11px;
        font-family: graphik;
        width: 90% !important;
        //padding-left: 50px;
        //margin-right: 50px;
    }
}
@media screen and (max-width: 600px) {
    .deferral-field22 {
        background-color: green;
        text-align: left;
        font-size: 11px;
        font-family: graphik;
        width: 80%;
        //padding-left: 50px;
        //margin-right: 50px;
    }
}
