.spinner {
    position: relative;
    margin: 0 auto;
}

.spinner::before {
    content: '';
    display: block;
    padding-top: 100%;
}

.spinner svg.circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

@media screen and (min-width: 0\0) {
    .spinner svg.circular {
        animation: rotate 1.5s linear infinite !important;
    }
}

@supports (-ms-ime-align: auto) {
    .spinner svg.circular {
        animation: rotate 1.5s linear infinite !important;
    }
}

.spinner svg.circular circle.path {
    stroke: #187aba;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation:
        dash 2s ease-in-out infinite,
        spinner 4s ease-in-out infinite;
}

@media screen and (min-width: 0\0) {
    .spinner svg.circular circle.path {
        stroke: #187aba;
        stroke-dasharray: 115, 200;
    }

    .branded-etrade .spinner svg.circular circle.path {
        stroke: #187aba;
    }

    .branded-morgan-stanley .spinner svg.circular circle.path {
        stroke: #1173b0;
    }

    .theme-light .spinner svg.circular circle.path {
        stroke: #187aba;
    }

    .theme-dark .spinner svg.circular circle.path {
        stroke: #ab80ff;
    }
}

@supports (-ms-ime-align: auto) {
    .spinner svg.circular circle.path {
        stroke: #187aba;
        stroke-dasharray: 115, 200;
        animation: none;
    }

    .branded-etrade .spinner svg.circular circle.path {
        stroke: #187aba;
    }

    .branded-morgan-stanley .spinner svg.circular circle.path {
        stroke: #1173b0;
    }

    .theme-light .spinner svg.circular circle.path {
        stroke: #187aba;
    }

    .theme-dark .spinner svg.circular circle.path {
        stroke: #ab80ff;
    }
}

@media (prefers-reduced-motion: reduce) {
    .spinner svg.circular circle.path {
        stroke: #187aba;
        animation: spinner 4s ease-in-out infinite;
        stroke-dasharray: 100, 200;
    }

    .branded-etrade .spinner svg.circular circle.path {
        stroke: #187aba;
    }

    .branded-morgan-stanley .spinner svg.circular circle.path {
        stroke: #1173b0;
    }

    .theme-light .spinner svg.circular circle.path {
        stroke: #187aba;
    }

    .theme-dark .spinner svg.circular circle.path {
        stroke: #ab80ff;
    }
}

.branded-etrade .spinner svg.circular circle.path {
    stroke: #187aba;
}

.branded-morgan-stanley .spinner svg.circular circle.path {
    stroke: #1173b0;
}

.theme-light .spinner svg.circular circle.path {
    stroke: #187aba;
}

.theme-dark .spinner svg.circular circle.path {
    stroke: #ab80ff;
}

.spinner svg.circular circle.background {
    stroke: #e9e9e9;
}

.branded-etrade .spinner svg.circular circle.background {
    stroke: #e9e9e9;
}

.branded-morgan-stanley .spinner svg.circular circle.background {
    stroke: #e9e9e9;
}

.theme-light .spinner svg.circular circle.background {
    stroke: #e9e9e9;
}

.theme-dark .spinner svg.circular circle.background {
    stroke: #424a5f;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 0, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 125, 200;
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dasharray: 125, 200;
        stroke-dashoffset: -125px;
    }
}

.modal.modal-spinner,
.modal-spinner {
    background-color: #fff;
    padding: 0;
}

@media print {
    .modal.modal-spinner,
    .modal-spinner {
        display: none !important;
    }
}

.branded-etrade .modal.modal-spinner,
.branded-etrade .modal-spinner {
    background-color: #fff;
}

.branded-morgan-stanley .modal.modal-spinner,
.branded-morgan-stanley .modal-spinner {
    background-color: #fff;
}

.theme-light .modal.modal-spinner,
.theme-light .modal-spinner {
    background-color: #fff;
}

.theme-dark .modal.modal-spinner,
.theme-dark .modal-spinner {
    background-color: #19233c;
}

.modal.modal-spinner.in,
.modal-spinner.in {
    opacity: 0.8;
}

.modal.modal-spinner .modal-dialog,
.modal.modal-spinner .modal-content,
.modal.modal-spinner .modal-body,
.modal-spinner .modal-dialog,
.modal-spinner .modal-content,
.modal-spinner .modal-body {
    height: 100%;
}

.modal.modal-spinner .modal-dialog,
.modal-spinner .modal-dialog {
    margin: 0;
}

.modal.modal-spinner .modal-content,
.modal-spinner .modal-content {
    background: rgba(0, 0, 0, 0);
    border: 0;
}

.modal.modal-spinner .modal-body,
.modal-spinner .modal-body {
    display: flex;
}

.spinner-overlay.show-spinner {
    position: relative;
}

.spinner-overlay.show-spinner .spinner-overlay-spinner {
    display: flex;
}

.spinner-overlay .spinner-overlay-spinner {
    background-color: #fff;
    opacity: 0.8;
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1100;
}

.branded-etrade .spinner-overlay .spinner-overlay-spinner {
    background-color: #fff;
}

.branded-morgan-stanley .spinner-overlay .spinner-overlay-spinner {
    background-color: #fff;
}

.theme-light .spinner-overlay .spinner-overlay-spinner {
    background-color: #fff;
}

.theme-dark .spinner-overlay .spinner-overlay-spinner {
    background-color: #19233c;
}

.branded-etrade.modal-spinner {
    background-color: #fff;
}

.branded-morgan-stanley.modal-spinner {
    background-color: #fff;
}

.modal-spinner {
    background-color: #fff;
}

.theme-light.modal-spinner {
    background-color: #fff;
}

.theme-dark.modal-spinner {
    background-color: #19233c;
}

.toast-container {
    height: auto;
    position: fixed;
    bottom: 0;
    top: initial;
    left: 0;
    width: 100%;
    z-index: 99999;
}

@media (min-width: 768px) {
    .toast-container {
        top: 0;
        left: 0;
        bottom: initial;
        height: 0;
    }
}
